import { FC } from 'react'
import { useRouter } from 'next/router'
import NextHead from 'next/head'
import Script from 'next/script'
import { GA4_MEASUREMENT_ID, MOENGAGE_APP_ID } from '@framework/utils/constants'


// const NOT_ALLOWED_SLUG_FOR_IAR = ['/cart', '/checkout']
const ALLOWED_SLUG_FOR_IAR = [
  '/p/boxer-shorts-colombo-blue',
  '/men/vests',
  '/men/trunks',
]

const showScript = (pathName: string) => {
  let isShow = false
  for (let i = 0; i < ALLOWED_SLUG_FOR_IAR.length; i++) {
    if (pathName.includes(ALLOWED_SLUG_FOR_IAR[i])) {
      isShow = true
      break
    }
  }
  return isShow
}

const Head: FC<any> = ({ pageProps }: any) => {
  // const [isInApp, setIsInApp] = useState<boolean | null>(null)
  const router = useRouter()
  const pathNameForIAR = router.asPath
  const isMicrosoftClarityEnable =
    process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ENABLE === 'true'
  const microsoftClarityProjectId =
    process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_PROJECT_ID

  const seoInfo =
    pageProps?.metaTitle ||
    pageProps?.metaDescription ||
    pageProps?.metaKeywords
      ? pageProps
      : pageProps?.data?.product || undefined

  const seoImage =
    pageProps?.metaTitle ||
    pageProps?.metaDescription ||
    pageProps?.metaKeywords
      ? pageProps?.products?.images[0]?.url
      : pageProps?.data?.product?.image || undefined

  const NEXT_PUBLIC_AUDITZY_RUM_BETA_KEY =
    process.env.NEXT_PUBLIC_AUDITZY_RUM_BETA_KEY

  const NEXT_PUBLIC_AUDITZY_IAR_KEY = process.env.NEXT_PUBLIC_AUDITZY_IAR_KEY



//   useEffect(() => {
//     if (typeof navigator !== 'undefined' || typeof window !== 'undefined') {
//       const userAgent =
//         navigator?.userAgent?.toLowerCase() || navigator?.vendor?.toLowerCase()
//       // window?.opera?.toLowerCase()

//       const rules = [
//         'WebView',
//         '(iPhone|iPod|iPad)(?!.*Safari/)',
//         'Android.*(wv)',
//       ]
//       const regex = new RegExp(`(${rules.join('|')})`, 'ig')
//       const isInAppFlag = Boolean(userAgent.match(regex))
//       // setIsInApp(isInAppFlag)
//       // console.log(':::: isInAppFlag ', isInAppFlag)
//     }
//   }, [])
  return (
    <>
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />

        {seoInfo && (
          <>
            <title>{seoInfo?.metaTitle}</title>
            <link
              rel="canonical"
              id="canonical"
              href={
                seoInfo?.canonicalTags ||
                'https://www.damensch.com' + router.asPath
              }
            />
            <meta name="title" content={seoInfo?.metaTitle} />
            <meta name="description" content={seoInfo?.metaDescription} />
            <meta name="keywords" content={seoInfo?.metaKeywords} />
            <meta
              property="og:title"
              content={seoInfo?.metaTitle}
              key="ogtitle"
            />
            <meta
              property="og:description"
              content={seoInfo?.metaDescription}
              key="ogdesc"
            />
          </>
        )}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="DaMENSCH" key="ogsitename" />
        <meta
          property="og:url"
          content={'https://www.damensch.com' + router.asPath}
          key="ogurl"
        />
        <meta property="og:image" content={seoImage} />
      </NextHead>

      {NEXT_PUBLIC_AUDITZY_IAR_KEY && showScript(pathNameForIAR) && (
        <Script
          id="adz_iar"
          strategy="afterInteractive"
          src={`${NEXT_PUBLIC_AUDITZY_IAR_KEY}`}
        />
      )}

      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/mobile-detect/1.4.5/mobile-detect.min.js"
        strategy="lazyOnload"
        onLoad={() => {}}
      />

      <Script
        strategy="beforeInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GA4_MEASUREMENT_ID}' );
        `,
        }}
      />

      <Script
        strategy="beforeInteractive"
        id="appsFlyer"
        dangerouslySetInnerHTML={{
          __html: `
          !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
            (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
            t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
            o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
            p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba",{pba: {webAppId: "d89a2527-3e7a-4435-b2a2-fb71a2ec836a"}});
          `,
        }}
      />

      <Script
        strategy="lazyOnload"
        id="moengage"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
            Moengage = moe({
            app_id:"${MOENGAGE_APP_ID}",
            debug_logs: 0
            });
          `,
        }}
      />

      {/* (window,document,"script",0,"AF", "pba,banners",{pba: {webAppId: "YOUR_PBA_KEY"}, banners: {key: "YOUR_WEB_KEY"}}); */}
      {/* AF('banners', 'showBanner',{bannerContainerQuery: "#banner-container"}); */}
      {/* remove lazy loading because causing issue in loading smart one link */}
      <Script
        src="https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js"
        strategy="beforeInteractive"
      />

      <Script
        id="rum"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(){const win=window;const store=sessionStorage;(function(win){const queue=[];const adz=function(){queue.push(arguments)};adz.q=queue;win.adz=adz})(window);let sd;sd=JSON.parse(store.getItem("adz")||'{ "views": 0 }');sd.views+=1;if(sd.urls&&sd.regex){const page=eval("("+sd.regex+")")(sd.urls,location.pathname);if(page&&!page.type){sd.page=page;store.setItem("adz",JSON.stringify(sd))}}const se=document.createElement("script");se.src="${process.env.NEXT_PUBLIC_AUDITZY_RUM_KEY}";document.head.appendChild(se);win.adz.storage=sd})();`,
        }}
      />

      {NEXT_PUBLIC_AUDITZY_RUM_BETA_KEY && (
        <Script
          id="adz_rum"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `(() => {
                const s = document.createElement("script");
                s.src = "${NEXT_PUBLIC_AUDITZY_RUM_BETA_KEY}";
                document.head.appendChild(s);
              })()`,
          }}
        />
      )}

      {isMicrosoftClarityEnable && (
        <Script
          strategy="lazyOnload"
          id="microsoft-clarity"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${microsoftClarityProjectId}");`,
          }}
        />
      )}
    </>
  )
}

export default Head
